import { useEffect } from 'react';

import { useAuth } from '../../Contexts/AuthContext';
import { useAnonymousAuth } from '../../Contexts/AnonymousAuthContext';
import { useConfig } from '../../Contexts/ConfigContext';

import goToLoginPage from '../../Utils/goToLoginPage';

const usePrivateRoute = () => {
  const { isLoggedIn, loading, userInfo } = useAuth();
  const { isAnonymousLoggedIn, isAnonymousLoading } = useAnonymousAuth();
  const { loginUrl, disableValidateUserInformation, enableAnonymousUser } = useConfig();

  useEffect(() => {
    const isInformationVerified = userInfo?.isVerified;

    if (loading) return;
    if (enableAnonymousUser && isAnonymousLoading) return;

    if (isLoggedIn && isInformationVerified) return;
    if (isLoggedIn && disableValidateUserInformation) return;
    if (enableAnonymousUser && isAnonymousLoggedIn) return;

    goToLoginPage({ loginUrl });
  }, [
    isLoggedIn,
    isAnonymousLoggedIn,
    loading,
    loginUrl,
    userInfo,
    userInfo?.isVerified,
    disableValidateUserInformation,
    enableAnonymousUser,
    isAnonymousLoading,
  ]);

  if (enableAnonymousUser) {
    return loading || isAnonymousLoading;
  }

  return loading;
};

export default usePrivateRoute;

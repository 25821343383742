import { createContext, useContext, useState, useCallback, useEffect } from 'react';
import urlJoin from 'url-join';

import { useConfig } from '../ConfigContext/ConfigProvider';
import fetch from '../Utils/fetch';
import { useAuth } from '../AuthContext';

const AnonymousAuthContext = createContext();

const ANONYMOUS_LOGIN_STATE = {
  LOADING: 'LOADING',
  LOGGED_IN: 'LOGGED_IN',
  NOT_LOGGED_IN: 'NOT_LOGGED_IN',
};
const AUTHORIZATION_REQUIRED_RESPONSE_CODE = 401;

const isAuthenticationError = (errorObj) =>
  errorObj?.statusCode === AUTHORIZATION_REQUIRED_RESPONSE_CODE;

const AnonymousAuthProvider = ({ children }) => {
  const { isLoggedIn, errorState, loading } = useAuth();
  const { userAPI, enableAnonymousUser } = useConfig();
  const [anonymousUserState, setAnonymousUserState] = useState(ANONYMOUS_LOGIN_STATE.LOADING);

  const ANONYMOUS_USER_PATH = urlJoin(userAPI, '/anonymous/me');
  const ANONYMOUS_USER_LOGIN_PATH = urlJoin(userAPI, '/anonymous/login');

  const setAnonymousUserId = useCallback(async () => {
    try {
      const result = await fetch(ANONYMOUS_USER_LOGIN_PATH, null, fetch.METHOD.POST);
      const anonymousUserId = result.anonymousUser?.id;

      if (anonymousUserId) {
        console.log('ANONYMOUS LOGGED IN');
        setAnonymousUserState(ANONYMOUS_LOGIN_STATE.LOGGED_IN);
      } else {
        throw Error('Unable to retrieve anonymous user id');
      }
    } catch (error) {
      console.error('Error logging in as anonymous user', error);
      setAnonymousUserState(ANONYMOUS_LOGIN_STATE.NOT_LOGGED_IN);
    }
  }, [ANONYMOUS_USER_LOGIN_PATH]);

  const anonymousUserLogin = useCallback(async () => {
    const isNotLoggedInUser = isAuthenticationError(errorState);

    try {
      if (isNotLoggedInUser) {
        const anonymousData = await fetch(ANONYMOUS_USER_PATH);

        const anonymousUserId = anonymousData?.anonymousUser?.id;

        if (!!anonymousUserId) {
          setAnonymousUserState(ANONYMOUS_LOGIN_STATE.LOGGED_IN);
        } else {
          await setAnonymousUserId();
        }
      } else {
        throw Error('The status code does not return 401');
      }
    } catch (error) {
      console.error('Error checking anonymous user path >>', error);
      setAnonymousUserState(ANONYMOUS_LOGIN_STATE.NOT_LOGGED_IN);
    }
  }, [ANONYMOUS_USER_PATH, errorState, setAnonymousUserId]);

  useEffect(() => {
    if (loading) return;

    if (enableAnonymousUser && !isLoggedIn) {
      anonymousUserLogin();
    } else {
      setAnonymousUserState(ANONYMOUS_LOGIN_STATE.NOT_LOGGED_IN);
    }
  }, [enableAnonymousUser, anonymousUserLogin, isLoggedIn, loading]);

  return (
    <AnonymousAuthContext.Provider
      value={{
        isAnonymousLoggedIn: anonymousUserState === ANONYMOUS_LOGIN_STATE.LOGGED_IN,
        isAnonymousLoading: anonymousUserState === ANONYMOUS_LOGIN_STATE.LOADING,
      }}
    >
      {children}
    </AnonymousAuthContext.Provider>
  );
};

const useAnonymousAuth = () => {
  const context = useContext(AnonymousAuthContext);

  if (context === undefined) {
    throw new Error('useAnonymousAuth must be used within AnonymousAuthContext');
  }

  return context;
};

export { AnonymousAuthProvider, useAnonymousAuth };
